import React, { useMemo } from 'react';
import Unity, { UnityContext } from 'react-unity-webgl';

const App = () => {
	const unityContext = useMemo(
    () =>
      new UnityContext({
        loaderUrl: 'Build/sneak-peek.loader.js',
        dataUrl: 'Build/sneak-peek.data',
        frameworkUrl: 'Build/sneak-peek.framework.js',
        codeUrl: 'Build/sneak-peek.wasm',
		}),
		[],
	);

	unityContext.on('OpenUrlInNewTab', str => {
		window.open(str);
	});

	unityContext.on('Alert', str => {
		window.alert(str);
	});

	unityContext.on('SendWebCommand', (cmd, param) => {
		console.log({ cmd, param });
		switch (cmd) {
			case 'ShowLoginModal':
				window.alert("Show login modal");
				break;
			case 'ShowInputModal':
				window.alert("Show input modal");
				break;
			case 'saveData':
				break;
			case 'requestResource':
				window.alert("Request resource");
			default:
				break;
		}
	});

	unityContext.on('IsMobilePlatform', () => {
		return false;
	});
  
	unityContext.on('TellUnityCanGetData', () => {
		unityContext.send('Hub', 'Init',
		  JSON.stringify({
			playerName: "Timothy",
			playerId:"",
			avatarURL: "https://public.weyrdworks.my/actgame/portrait.png",
			hasActivated: 0,
			activationCodes: ["80808","10101"],
			episodeProgress: [0]
		  }),
		);
	});

	unityContext.setFullscreen(false);

  return (
	<div>
		<Unity
		  unityContext={unityContext}
		  style={{
			width: '100%'
		  }}
		  tabIndex={1}
		/>
	</div>
  );
};

export default App;